import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet, HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <Helmet>
      <title>
        Cold Email Subject Line Generator - Generate Cold Email Subject Lines
        via AI
      </title>
      <meta
        name="description"
        content="Discover the ultimate tool for crafting compelling cold email subject lines that drive higher open rates. Elevate your outreach game and engage your audience effectively with our innovative Cold Email Subject Line Generator. Try it now!"
      />
      <meta
        name="keywords"
        content="good cold email subject lines, good subject line for cold email, best subject line for cold email, best cold email subject lines, best subject line for a cold email, best subject lines for cold email, best email subject lines for cold emails, best cold email subject line, great cold email subject lines, cold email subject line, cold email subject lines, subject line for a cold email, cold email subject, subject line cold email, email subject lines for cold emails, subject lines cold email, subject line for cold email"
      />
    </Helmet>
    <App />
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
