import React from "react";
import NumailBackground from "../../../img/NumailBackground.png";
import { NumailBackgroundBottom } from "../../../components/icons";

export const BackgroundStyling = () => {
  return (
    <>
      <div className="absolute top-[-5rem] z-[-1]">
        <img src={NumailBackground} alt="" />
      </div>
      <div className=" fixed bottom-[1rem] right-[1rem] z-50 bg-[#232529] px-3 py-2 rounded-[0.8rem] flex gap-2 items-center">
        <NumailBackgroundBottom />
        <p
          className=" text-white text-[0.8rem]"
          style={{
            background: "linear-gradient(to bottom, #FFFFFF, #949494)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
          }}
        >
          Powered By Numail
        </p>
      </div>
    </>
  );
};
