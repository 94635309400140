import React from "react";
import {
  ArrowIcon,
  FacebookIcon,
  FireIcon,
  NumailLogo,
  XIcon,
  YoutubeIcon,
} from "../../components/icons";
import { Avatar } from "../../components/Avatar";
import Avatar1 from "../../img/Avatar1.png";
import Avatar2 from "../../img/Avatar2.png";
import Avatar3 from "../../img/Avatar3.png";
import { Popover } from "antd";

export const LandingPage = ({ setIndex }) => {
  return (
    <div className="flex flex-col items-center mt-8">
      <NumailLogo />
      <div className="flex gap-2 items-center mt-5">
        <FireIcon />
        <p className="text-[#69696A] text-[18px]">
          Check Out Our Cold Email Template Directory
        </p>
      </div>
      <div className="flex justify-center">
        <p
          className="text-[60px] text-white w-[71%] text-center font-extrabold"
          style={{
            background: "linear-gradient(to right, #FFFFFF, #545353)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
          }}
        >
          Generate Cold Email Subject Lines 150% increase in email open rates,
          for FREE
        </p>
      </div>
      <div className="mt-4">
        <p className="text-[16px] text-white text-center">
          Cold Email Subject Generator is a tool to help you generate subject
          lines. We know the pain and we understand the problem.
        </p>
        <p className="text-[16px] text-white text-center">
          Click the "Generate" button below, we've build a custom AI model and
          trained it on 1M+ subject lines from millions of campaigns.
        </p>
      </div>
      <div className="mt-8 flex gap-4">
        <button
          className="text-white border-[1px] border-[#4F5054]  rounded-lg flex gap-2 items-center justify-center px-3 py-1"
          onClick={() => setIndex(1)}
        >
          <span className="text-[20px]">Generate</span>
          <ArrowIcon />
        </button>
      </div>
      <div className="mt-4 flex gap-4 items-center">
        <div className="flex">
          <Avatar isMargin={false} img={Avatar1} />
          <Avatar isMargin={true} img={Avatar2} />
          <Avatar isMargin={true} img={Avatar3} />
        </div>

        <p className="text-[16px] text-[#6B7280]">
          Join 20,000+ others who signed up
        </p>
      </div>
      <div className="flex items-center mt-4 gap-3">
        <FacebookIcon />
        <YoutubeIcon />
        <XIcon />
      </div>
      <div className="flex gap-4 mb-[4rem] mt-8">
        <MicroservicesComponent
          header={"Email Template Library"}
          text={`Cold Email Library is a curated list of cold email templates for a wide range of use cases & niches. Over 2,000 templates for FREE.`}
        />
        <MicroservicesComponent
          header={"Cold Email Mafia"}
          text={`Cold Email Mafia is a private community for cold emailers. To qualify to join,
           you need to be sending at least 10,000 emails a month and willing to share your journey with the rest.
                 We also have cool badges & rankings for our Mafia Bosses.`}
        />
        <MicroservicesComponent
          header={"DMARC, DKIM, SPF & Reputation Checker"}
          text={
            "We trained an AI model on over 200,000 cold emails with over 10,000 different subject lines. Give us the right info and we’ll generate 10 cold email subject lines that are guaranteed to outperform “Quick Question?”"
          }
        />
      </div>
    </div>
  );
};

const MicroservicesComponent = ({ header, text }) => {
  const content = (
    <div className="w-[14.5rem]">
      <p className="text-white text-center">{text}</p>
    </div>
  );

  return (
    <Popover content={content}>
      <div className="bg-[#141414] py-4 px-8 flex gap-2 items-center rounded-md w-[17rem] justify-center">
        <p
          style={{
            background: "linear-gradient(to right, #FFFFFF, #888888)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
          }}
        >
          {header}
        </p>
        <div className="mt-1">
          <ArrowIcon />
        </div>
      </div>
    </Popover>
  );
};
